import React from "react";
import { Data } from "./Data";
import _get from 'lodash/get';
import {inject, observer} from "mobx-react";
import styled from 'styled-components';
import {Paths} from "../../Routs/Paths";
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import { AppointmentsSection } from "./AppointmentsSection";
import { ServiceRequestsSection } from "./ServiceRequestsSection";
import AccessDenied from "GUI_MAIN/Components/AccessDenied";
import Scrollbar from "react-scrollbars-custom";
import {
    ACCESS_RIGHT_ACTION,
    ACCESS_RIGHT_OBJECT,
    ACCESS_RIGHT_INCLUDES,
} from "MODEL_STORE/DataSource/Constants/user_access_rights";
import {ConfirmSnilsOrEmail} from "GUI_MAIN/Components/ConfirmSnilsOrEmail/ConfirmSnilsOrEmail";
import {CustomVersion} from "MODEL_STORE/DataSource/Stores/CustomVersion";
import { reaction } from "mobx";
import { Translator } from "eo-locale";
import { locales } from "Locale";
import { observable } from "mobx";

import { DialogPopup } from "GUI_MAIN/Components/MisIntegration/Dialog/DialogPopup";

export const LOCAL_STORE_FILTER_KEY = "MY_SERVICEREQUEST";

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${props => props.theme.mainBGGray};
`;

const ScrollBarInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${props => props.noSearch ? '24px' : ''};
`;

@inject('AuthStore')
@inject('ContextualMenuStore')
@observer
export default class Dashboard extends React.PureComponent {

    /**
     * данные о календаре, заявках и консилиумах
     * если нет прав, то данные не инициализируем
     */
    data = null;

    @observable isMyRequests = false;


    constructor(props) {
        super(props);

        const translator = new Translator(HeaderStore.locale, locales);

        HeaderStore.setTitle("SS001001");
        HeaderStore.setBreadcrumbs([]);
        document.title = translator.getMessageById('SS025004');
        this.data = this.hasAccessRights() ? new Data() : null;
        this.state = {
            showPopupStart: this.isShowIntegrationPopup(),
            showConfirmSnils: /\/confirm_snils_email$/.test(props.history.location.pathname),
        };
        this.loadFromLocalStore();
        reaction(() => HeaderStore.locale, () => {
            this.data = this.hasAccessRights() ? new Data() : null;
        })
    }

    isShowIntegrationPopup = () => {
        return localStorage.getItem("INTEGRATION_CONTEXT_ID") > "";
    }

    loadFromLocalStore = () => {
      let isMyRequestsFlag = localStorage.getItem(LOCAL_STORE_FILTER_KEY);
      if (!isMyRequestsFlag) {
         if(this.props.AuthStore.currentUserData.roles[0].extension) {
           this.isMyRequests = _get(this.props.AuthStore, "currentUserData.roles.0.extension.0.extension.0.url", '') === "arm_practitioner";
         }
        return null;
      }

      isMyRequestsFlag = JSON.parse(isMyRequestsFlag);
      this.isMyRequests = isMyRequestsFlag;
     }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.showPopupStart && !this.state.showConfirmSnils) {
            this.setState({
                showPopupStart: /\/start$/.test(nextProps.history.location.pathname),
                showConfirmSnils: /\/confirm_snils_email$/.test(nextProps.history.location.pathname),
            });
        }
    }

    /**
     * имеет ли пользователь права на просмотр рабочего стола?
     */
    hasAccessRights = () => {
        const {AuthStore} = this.props;
        if (AuthStore.currentUserData.isRoot())
            return true;
        return AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.DESKTOP, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL);
    };

    GlobalSearchasAccessRights = () => {
        const {AuthStore} = this.props;
        if (AuthStore.currentUserData.isRoot())
            return true;
        return AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.AVAILABILITY_OF_GLOBAL_SEARCH, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL);
    };

    /**
     * редирект на главную страницу
     */
    showDashboard = () => {
        return this.props.history.push(Paths.dashboard.path());
    }

    /**
     * редирект на список заявок
     */
    showServiceRequests = () => {
        return this.props.history.push(Paths.servicerequest.list.path());
    }

    /**
     * редирект на список консилиумов
     */
    showAppointments = () => {
        return this.props.history.push(Paths.appointment.list.path());
    }

    /**
     * редирект на создание заявки
     */
    createServiceRequest = () => {
        return this.props.history.push(Paths.servicerequest.create.path());
    }

    onMyRequestsChange = () => {
      this.isMyRequests = !this.isMyRequests;
      localStorage.setItem(LOCAL_STORE_FILTER_KEY, JSON.stringify(this.isMyRequests));
    }

    /**
     * отображать ли интеграционный диалог?
     */
    get showIntegrationDialog() {
        return /\/start$/.test(this.props.history.location.pathname);
    }

    render () {
        const {showPopupStart, showConfirmSnils} =this.state;
        if (showConfirmSnils)
            return <ConfirmSnilsOrEmail onClose={(callback) => this.setState({showConfirmSnils: false}, callback)}/>

        if( ! this.data ) {
            return <AccessDenied/>;
        }
        const {
            calendar,
            appointments,
            serviceRequests
        } = this.data;
        return (
            <ContentWrapper>
                <Scrollbar noScrollX={true}>
                    <ScrollBarInner noSearch={CustomVersion.emiasMode}>
                        {/*{*/}
                        {/*    (this.GlobalSearchasAccessRights()) &&*/}
                        {/*    <GlobalSearch history={this.props.history}/>*/}
                        {/*}*/}
                        <ServiceRequestsSection
                            onMyRequestsChange={this.onMyRequestsChange}
                            isMyRequests={this.isMyRequests}
                            serviceRequests={serviceRequests}
                            AuthStore={this.props.AuthStore}
                            redirect={this.props.history.push}
                            showServiceRequests={this.showServiceRequests}
                            createServiceRequest={this.createServiceRequest}/>
                        <AppointmentsSection
                            calendar={calendar}
                            appointments={appointments}
                            redirect={this.props.history.push}
                            showAppointments={this.showAppointments}
                            serviceRequests={serviceRequests}
                            createServiceRequest={this.createServiceRequest}/>
                    </ScrollBarInner>
                </Scrollbar>
                { this.showIntegrationDialog && (
                    <DialogPopup onClose={this.showDashboard}/>
                ) }
            </ContentWrapper>
        );
    }
}
