import React from "react";
import { observer } from "mobx-react";
import { Calendar } from "./Calendar/index";
import styled from "styled-components";
import { Appointments } from "./Appointments/index";
import {errorBoundary} from "../../../../GUI_MAIN/ErrorBoundary/ErrorBoundary";
import {EOLocale} from "eo-locale";

const Wrapper = styled.div`
  flex-shrink: 0;
  height: 480px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 16px 20px;
  box-shadow: 0 2px 8px 0 #0000001A;
`;

const Title = styled.div`
  padding: 12px 16px;
  color: #232735;
  font-family: ${props=>props.theme.OpenSansSemiBold};
  font-size: ${props=>props.theme.bigFontSize};
  line-height: 24px;
`;

const MainTitle = styled(Title)`
  cursor: pointer;
  width: 345px;
  border-right: 1px solid ${props => props.theme.borderNavigation};
`;

const ContentContainer = styled.div`
  display: flex;
  height: calc(100% - 48px);
`;

const AppointmentsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Header = styled.div`
  background-color: #F2FAFD;
  display: flex;
`;

@errorBoundary(null, null, null, null, "AppointmentsSection")
@observer
export class AppointmentsSection extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render () {
        const {
          calendar,
          appointments,
          showAppointments,
          createServiceRequest,
          serviceRequests
        } = this.props;

        const title = <MainTitle onClick={showAppointments}><EOLocale.Text id={"SS001019"}/></MainTitle>;
        return (
            <Wrapper>
              <Header>
                {title}
                <Title>
                  {calendar.currentDate}
                </Title>
              </Header>
              <ContentContainer>
                  <Calendar calendar={calendar}/>
                  <AppointmentsWrapper>
                    <Appointments
                      appointments={appointments}
                      createServiceRequest={createServiceRequest}/>
                  </AppointmentsWrapper>
              </ContentContainer>
            </Wrapper>
        );
    }
}
